<template>
  <div class="policy-regulation container">
    <!-- 政策 -->
    <div class="policy">
      <h3>政策法规</h3>
      <div class="content">
        <div class="content-left">
          <el-carousel class="banner" indicator-position="none" height="590px">
            <el-carousel-item class="banner-box" v-for="v in bannerList" :key="v.id">
              <img @click="jump(v)" :src="baseURL + v.attachFilePath" alt="图签加载失败" />
              <div v-if="v.title" class="img-title text-overflow-ellipsis">
                <p class="title">{{v.title}}</p>
                <p class="time">{{ v.createTime | filterData }}</p>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="content-right">
          <el-timeline>
            <el-timeline-item v-for="v in policyList"
              :key="v.id" 
               placement="bottom" color="#FF5C00">
              <div class="right-content" @click="
                  $router.push({ name: '政策法规详情', query: { id: v.id } })
                ">
                <div class="createTime">
                  <div class="time">
                    <span style="font-size: 18px;color: #f36e31;margin-bottom: 8px;">
                      {{v.createTime|filterTime}}
                    </span>
                    <span style="font-size: 14px;color: #898A8D">
                      {{v.createTime|filterTimeYear}}
                    </span>
                  </div>
                  <span class="createTime-font">
                    发布时间
                  </span>
                </div>
                <h4 class="title">{{ v.title }}</h4>
                <p>{{ v.title }}</p>
              </div>
            </el-timeline-item>
            <el-timeline-item color="#FF5C00">
              <el-link class="get-more" :underline="false" href="/home/policy-regulation#/home/policy-regulation">查看更多 ···</el-link>
            </el-timeline-item>
          </el-timeline>
          <!-- <ul>
            <li
              v-for="v in policyList"
              :key="v.id"
              class="text-overflow-ellipsis"
            >
              <i class="iconfont icon-dian"></i>
              <span
                @click="
                  $router.push({ name: '政策法规详情', query: { id: v.id } })
                "
                >{{ v.title }}</span
              >
            </li>
          </ul> -->
        </div>
      </div>
    </div>

    <!-- 交流 -->
    <!-- <div class="exchange">
      <h3>互动交流</h3>
      <ul>
        <li v-for="v in exchangeList" :key="v.icon" @click="jumpExchange(v)">
          <p class="title">{{ v.title }}</p>
          <p class="icon">
            <i :class="v.icon"></i>
          </p>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import indexApi from "@/api/index";
import { mapState } from "vuex";

export default {
  created() {
    this.getPolicyList();
    this.renderBanner()
  },
  data() {
    return {
      bannerList: [],
      policyList: [],
      exchangeList: [
        { title: "反馈信箱", icon: "iconfont icon-youjian" },
        { title: "在线咨询", icon: "iconfont icon-zaixianzixun" },
        { title: "下载专区", icon: "iconfont icon-xiazaizhuanqu" },
        { title: "征集调查", icon: "iconfont icon-fangdajing" },
      ],
    };
  },
  computed: {
    ...mapState(["baseURL"])
  },
  filters: {
    filterTime(val) {
      return moment(val).format("MM/DD");
    },
    filterTimeYear(val) {
      return moment(val).format("YYYY");
    },
    filterData(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  methods: {
    async jump(item) {
      if (item.url) {
        await indexApi.imageClick({id: item.id})
        window.open(item.url, '_blank')
      }
    },
    // 获取轮播图列表
    async renderBanner() {
      let { code, data, message } = await indexApi.findBanner({
        module: 7 // 1: '顶部轮播图',2: '公告轮播图',6: '内页banner',7: '底部广告位'
      });
      console.log(data, 'data');
      this.bannerList = data;
    },
    // 获取政策法规列表
    async getPolicyList() {
      let { data } = await indexApi.findInformOrNewsList({
        module: 3, //(1:通知公告、2:最新动态、3:政策法规、4:下载专区、5:服务指南)
        page: 1,
        size: 6,
        sort: "asc",
        sortFiled: "sort",
      });
      this.policyList = data.records;
    },

    // 互动交流的跳转函数
    jumpExchange(v) {
      if (v.title === "下载专区") this.$router.push({ name: v.title });
    },
  },
};
</script>

<style lang="less" scoped>
.policy-regulation {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  .banner {
    background: #dfe6ff;
    .el-carousel__item {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        // height: 100%;
        // transform: scale(.5);
      }
      .img-title {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0 15px;
        color: white;
        background-color: rgba(0, 0, 0, 0.7);
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // padding-right: 50px;
        .title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 35px;
          padding-right: 20px;
          margin-top: 6px;
        }
        .time {
          font-size: 12px;
          margin-bottom: 15px;
        }
      }
    }
  }
  /deep/.el-timeline-item__tail{
    border-left: 2px dotted #FF5C00;
  }
  /deep/.el-timeline-item__timestamp{
    font-size: 16px;
    color: #F36E31;
    position: absolute;
    left: -84px;
    top: -6px;
    // &::after{
    //   color: #898A8D;
    //   content: '发布时间' ;
    //   border-top: #ECECF1 1px solid;
    //   font-size: 14px;
    //   display: block;
    //   padding-top: 10px;
    //   margin-top: 10px;
    // }
    
  }
  /deep/.el-timeline .el-timeline-item{
    &:last-child{
      .el-timeline-item__timestamp{
        &::after{
          content: '' ;
        }
      }
    }
  }
  // 政策
  .policy {
    width: 100%;
    .content{
      display: flex;
      .content-left{
        width: 463px;
        img{
          width: 463px;
        }
      }
      .content-right{
        padding: 10px 20px;
        margin-left: 80px;
        h4{
          color: #20201E;
          font-size: 18px;
          line-height: 1.3em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 590px;
        }
        flex: 1;
        .right-content{
          cursor: pointer;
          height: 70px;
          position: relative;
          p{
            margin-top: 16px;
            color: #494A4C;
          }
          .title{
            &:hover{
              color: #F36E31;
            }
          }
          .createTime{
            font-size: 14px;
            position: absolute;
            left: -100px;
            top: 0;
            display: flex;
            flex-direction: column;
            .time{
              display: flex;
              flex-direction: column;
            }
            .createTime-font{
              font-size: 12px;
              color: #898A8D;
              border-top: 1px solid #ECECF1;
              line-height: 24px;
              margin-top: 8px;
            }
          }
        }
      }
    }
    h3 {
      font-size: 18px;
      color: #20201e;
      line-height: 40px;
      text-align: left;
      border-bottom: 1px solid #ececf1;
      margin-bottom: 20px;
    }
    .get-more{
      width: 166px;
      height: 44px;
      color: #F36E31;
      border: 1px solid #F36E31;
      border-radius: 6px;
      transition: all 0.3s;
      &:hover{
        background: #F36E31;
        color: #fff;
      }
    }
  }

  // 交流
  .exchange {
    width: 380px;
    h3 {
      font-size: 22px;
      margin-bottom: 20px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        background: #efefef;
        width: 180px;
        margin: 0 10px 10px 0;
        padding: 10px;
        transition: all 1s; //让hover效果有渐变的感觉

        // 鼠标移入li的效果
        &:hover {
          cursor: pointer;
          background: #3660d9;
          .title {
            color: white;
          }
          .icon {
            i {
              color: white;
            }
          }
        }

        .title {
          margin-bottom: 10px;
        }
        .icon {
          text-align: right;
          line-height: 28px;
          .iconfont {
            color: #3660d9;
            &.icon-youjian {
              font-size: 19px;
            }
            &.icon-zaixianzixun {
              font-size: 25px;
            }
            &.icon-xiazaizhuanqu {
              font-size: 24px;
            }
            &.icon-fangdajing {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}
</style>