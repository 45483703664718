<template>
  <div class="index">
    <ShowData></ShowData>

    <InfoSearch></InfoSearch>

    <!-- 通知公告、最新动态（新闻News） -->
    <News></News>

    <ShowSync></ShowSync>

    <Help></Help>
    
    <Download></Download>

    <!-- <Service></Service> -->
    <!-- 工程建设、政府采购的一些公告、通知、公示 -->
    <!-- <Notice></Notice> -->

    <!-- 平台统计 -->
    <!-- <platform-total></platform-total> -->

    <!-- 日程 -->
    <!-- 暂时注释 -->
    <!-- <Schedule></Schedule> -->

    <!-- 政策法规 --> 
    <!-- <policy-regulation></policy-regulation> -->
    <!-- <inter-active></inter-active> -->
  </div>
</template>

<script>
// import News from "@/components/Index/News.vue";
import Notice from "@/components/Index/Notice.vue";
import PlatformTotal from "@/components/Index/PlatformTotal.vue";
// import Schedule from "@/components/Index/Schedule.vue";
import PolicyRegulation from "@/components/Index/PolicyRegulation.vue";
// import InterActive from "@/components/Index/InterActive.vue";
import Service from "@/components/Layout/Service.vue";
import ShowData from "@/components/Layout/ShowData.vue";
import InfoSearch from "@/components/Layout/InfoSearch.vue";
import News from "@/components/Layout/News.vue";
import ShowSync from "@/components/Layout/ShowSync.vue";
import Help from "@/components/Layout/Help.vue";
import Download from "@/components/Layout/Download.vue";

export default {
  // components: { Download, Help, ShowSync, InfoSearch, ShowData, News, Notice, PlatformTotal, PolicyRegulation, Service }
  components: { Download, Help, ShowSync, InfoSearch, ShowData, News }
};
</script>

<style lang="less" scoped>
.index {
  // margin-top: 50px;
}
</style>