<template>
  <div class="download">
    <div class="container">
      <h1>下载专区
        <div class="right" @click="more">
          查看更多
          <div class="icon"></div>
        </div>
      </h1>
      <div class="box">
        <div>
          <div class="bg-img tb">
            <div class="black">
              <img src="@/assets/images/new-ui/ic_tbgjxz.svg" alt="">
              投标工具下载
            </div>
          </div>
          <ul class="right-list">
            <li v-for="item of listData1" :key="item.id" @click="goDetail(item)">
              <div class="title">
                <h3>
                  {{ item.title }}
                </h3>
                <div>
                  <span class="more">查看详情</span>
                  <img class="svghover" src="@/assets/images/new-ui/ic_xq_hover.svg" alt="">
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <div class="bg-img qt">
            <div class="black">
              <img src="@/assets/images/new-ui/ic_qtxz.svg" alt="">
              其他下载
            </div>
          </div>
          <ul class="right-list">
            <li v-for="item of listData2" :key="item.id" @click="goDetail(item)">
              <div class="title">
                <h3>
                  {{ item.title }}
                </h3>
                <div>
                  <span class="more">查看详情</span>
                  <img class="svghover" src="@/assets/images/new-ui/ic_xq_hover.svg" alt="">
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indexApi from "@/api/index.js";
export default {
  data() {
    return {
      listData: [
        {id:1, title: '投标工具下载'},
        {id:2, title: '电子签章工具'},
        {id:3, title: '人格分他依然交dfgsdfwwe而非人为非人为委托方微软w互体验尔特让我'},
      ],
      listData1: [],
      listData2: [],
    }
  },
  created() {
    this.renderList1()
    this.renderList2()
  },
  methods: {
    more() {
      this.$router.push({
        name: '下载专区',
      })
    },
    async clickNum(id) {
      await indexApi.imageClick({id})
    },
    goDetail(item) {
      this.$router.push({
        name: '下载专区详情',
        query: {
          id: item.id
        }
      })
      this.clickNum(item.id)
    },
    async renderList1() {
      let { code, data, message } = await indexApi.findInformOrNewsList({
        page: 1,
        size: 3,
        module: '4', //(1.通知公告 2.最新动态 3政策法规 4下载中心 6 帮助中心)
        serviceType: '1',
        sort: "asc",
        sortFiled: "sort",
      });
      if (code === 200) {
        this.listData1 = [...data.records]
      }
    },
    async renderList2() {
      let { code, data, message } = await indexApi.findInformOrNewsList({
        page: 1,
        size: 3,
        module: '4', //(1.通知公告 2.最新动态 3政策法规 4下载中心 6 帮助中心)
        serviceType: '2',
        sort: "asc",
        sortFiled: "sort",
      });
      if (code === 200) {
        this.listData2 = [...data.records]
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .download {
    height: 526px;
    padding: 58px 0;
    background: #F3F5F7;
    h1 {
      color: #333;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      .right {
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: #999;
        align-items: center;
        transition: color 0.2s;
        font-weight: normal;
        cursor: pointer;
        div {
          line-height: 24px;
        }
        &:hover {
          color: #F36E31;
          .icon {
            background-image: url(~@/assets/images/new-ui/ic_more_hover.svg);
          }
        }
        .icon {
          background-image: url(~@/assets/images/new-ui/ic_more.svg);
          width: 24px;
          height: 24px;
          background-size: 24px 24px;
          display: block;
          
        }
      }
    }
    .box {
      margin-top: 30px;
      height: 330px;
      display: flex;
      justify-content: space-between;
      >div {
        box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
        background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
        border-radius: 12px 12px 12px 12px;
        border: 2px solid #FFFFFF;
        flex: 1;
        padding: 32px 24px;
        .right-list {
          height: 100%;
          width: 100%;
          margin-top: 26px;
          >li {
            position: relative;
            padding-left: 15px;
            color: #333;
            font-size: 16px;
            margin-bottom: 20px;
            cursor: pointer;
            display: flex;
            flex-direction: column;

            
            .title {
              display: flex;
              justify-content: space-between;
              align-items: center;

            }
            .time {
              color: #999;
              font-size: 14px;
              margin-top: 8px;
            }
            &:hover {
              .svghover {
                margin-right: 0px;
              }
              .more {
                opacity: 1;
              }
            }
            .more {
              color: #F36E31;
              opacity: 0;
              transition: opacity 0.3s;
              font-size: 14px;
              margin-right: 4px;
            }
            .svghover {
              transition: all 0.3s;
              margin-right: 10px;
            }
            h3 {
              width: 400px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &:hover::after {
              border: 2px solid #F36E31;
            }
            &::after {
              content: '';
              position: absolute;
              width: 6px;
              height: 6px;
              border: 2px solid #999999;
              border-radius: 6px;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              transition: border-color .3s;
            }
          }
        }
        .tb {
          background-image: url(~@/assets/images/new-ui/pic_tbgj@2x.png);
        }
        .qt {
          background-image: url(~@/assets/images/new-ui/pic_qtxz@2x.png);
        }
        .bg-img {
          background-size: auto 121px;
          height: 121px;
          border-radius: 12px;
          position: relative;
          .black {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(51,51,51,0.7);
            border-radius: 12px;
            color: #FFFFFF;
            padding-left: 24px;
            font-size: 18px;
            font-weight: bold;
            display: flex;
            align-items: center;
            > img {
              margin-right: 4px;
            }
          }
        }
        > img {
          height: 121px;
        }
      }
      >div:nth-child(1) {
        margin-right: 40px;
      }
    }
  }
</style>