<template>
  <div class="info-list">
    <ul class="list-data">
      <template v-if="propList.length > 0">
        <li v-for="item of propList" :key="item.id">
          <div class="title">
            <div>
              {{ item.typeName }}
              {{ item.electronic*1 === 1 ? '| 电子标' : item.electronic*1 === 0 ? '| 非电子标' : ''}}
            </div>
            <h3 @click="jumpDetail(item)">
              {{ item.title }}
            </h3>
            <div class="date">{{ item.releaseTime|filterTimeNoHms }}</div>

          </div>
          <div class="time" v-if="(item.typeName.indexOf('公告') !== -1) && item.typeName.indexOf('异常') == -1 && item.typeName.indexOf('合同') == -1">
            <div class="end-time">
              招标文件获取结束时间 {{ item.docGetEnd|filterTime }}
            </div>
            <div class="countdown">
              文件提交剩余时间 
              <div :class="new Date().getTime() > item.tenderDocSubmitEnd ? 'nored' : 'red'">
                <el-statistic
                  format="D天 HH:mm:ss"
                  :value="item.tenderDocSubmitEnd"
                  time-indices
                >
                </el-statistic>
              </div>
            </div>
          </div>
        </li>
      </template>
      <div class="no-data" v-else>
        <img src="@/assets/images/new-ui/gy_pic_ss_none@2x.png" alt="">
        <div>对不起，搜索无结果</div>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    propList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterTimeNoHms(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  data() {
    return {
    }
  },
  methods: {
        // 点击列表的跳转函数（跳到详情页）
    jumpDetail(v) {
      // 招标公告:notice 变更公告:change 补遗通知:addendum 异常公告:exception 结果公示:publicity
      // 结果公示（1评标结果公示 3中标结果公示 4流标公示）
      if (v.type === "notice")
        this.$router.push({
          name: "招采信息详情",
          query: { id: v.id, type: v.type, isGov: v.isGov }
        });
      if (v.type === "change")
        this.$router.push({
          name: "招采变更信息详情",
          query: { id: v.id, type: v.type, isGov: v.isGov }
        });
      if ( v.type === "contract") {
        this.$router.push({
          name: "合同公告详情",
          query: { id: v.id, type: v.type, isGov: v.isGov }
        });
      }
      if (v.type === "addendum")
        this.$router.push({
          name: "招采信息补遗通知详情",
          query: { id: v.id, type: v.type, isGov: v.isGov }
        });
      if (v.type === "exception")
        this.$router.push({
          name: "招采信息异常公告详情",
          query: { id: v.id, type: v.type, isGov: v.isGov }
        });
      // 结果公示
      if (
        v.type == 1 ||
        v.type == 2 ||
        v.type == 3 ||
        v.type == 4 ||
        v.type == 5
      )
        this.$router.push({
          name: "招采信息结果公示详情",
          query: {
            id: v.id,
            type: v.type,
            isGov: v.isGov,
            correctType: v.correctResultType, //修正类型（1.修正评标结果 2.修正流标结果）
            reviewResult: v.reviewResult //复核结果（1维持原结果 2变更原结果 3本次流标）
          }
        });
    },
  },
  created() {
  }
}
</script>

<style lang="less" scoped>
  .info-list {
    width: 100%;
    .list-data {
      margin-top: 16px;
      >li {
        margin-left: 14px;
        margin-bottom: 24px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background-color: #F36E31;
          left: -14px;
          top: 9px;
        }
        .title {
          display: flex;
          align-items: center;
          >h3 {
            flex: 1;
            color: #20201E;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            transition: color 0.2s;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 10px;
            &:hover {
              color: #F36E31;
            }
          }
          >div:nth-child(1) {
            background-color: #FFF0E8;
            font-size: 14px;
            color: #F36E31;
            height: 23px;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #FFE0CF;
            display: flex;
            align-items: center;
            padding: 0 10px;
            margin-right: 8px;
          }
          .date {
            color: #999;
            font-size: 14px;
          }
        }
        .time {
          display: flex;
          margin-top: 12px;
          color: #999;
          font-size: 14px;
          .end-time {
            border-radius: 4px;
            background-color: #F4F4F4;
            height: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            margin-right: 12px;
          }
          .countdown {
            display: flex;
            align-items: center;
            .red {
              color: #FA5151;
              font-weight: bold;
              margin-left: 4px;
            }
            .nored {
              margin-left: 4px;
              color: #999;
              font-weight: bold;
            }
          }
        }
      }
    }
    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #C3C3C3;
      margin-bottom: 32px;
    }
  }
</style>