<template>
  <div class="service">
    <div class="container">
      <ul>
        <router-link v-for="v in serviceList" :key="v.imgUrl" :to="v.route" tag="li">
          <img :src="v.imgUrl" alt="图片加载失败" />
          <div>
            <p class="title">{{ v.title }}</p>
            <span class="more">查看更多</span>
          </div>
        </router-link>
      </ul>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      serviceList: [
        {
          imgUrl: require("../../assets/images/newImages/icon-bszn@2x.png"),
          title: "办事指南",
          route: { name: "服务指南", query: { type: 1 } }
        },
        {
          imgUrl: require("@/assets/images/newImages/icon-bslc@2x.png"),
          title: "办事流程",
          route: { name: "服务指南", query: { type: 2 } }
        },
        {
          imgUrl: require("../../assets/images/newImages/icon-glgd@2x.png"),
          title: "管理规定",
          path: "",
          route: { name: "服务指南", query: { type: 3 } }
        },
        {
          imgUrl: require("../../assets/images/newImages/icon-szzsbl@2x.png"),
          title: "数字证书办理",
          path: "",
          route: { name: "服务指南", query: { type: 4 } }
        }
      ]
    };
  },
  methods: {
    
  }
};
</script>

<style lang="less" scoped>
.service{
  .container{
    ul{
      background: #FFF7F3;
      padding: 20px 16px;
      display: flex;
      li{
        display: flex;
        flex: 1;
        align-items: center;
        cursor: pointer;
        >div{
          margin-left: 16px;
        }
        .title{
          color: #20201E;
          font-weight: 600;
          margin-bottom: 20px;
        }
        .more{
          padding: 3px 8px;
          border: 1px solid #898A8D;
          color: #898A8D;
          height: 24px;
          border-radius: 12px;
          font-size: 12px;
          cursor: pointer;
          transition: all 0.3s;
          &:hover{
            color: #fff;
            background: #F36E31;
            border: 1px solid transparent;
          }
        }
        img{
          width: 80px;
        }
      }
    }
  }
}
</style>