<template>
  <div class="show-sync">
    <ADImg imgModule="8"></ADImg>
  </div>
</template> 

<script>
import ADImg from '@/components/ADImg'
export default {
  components: {
    ADImg
  },
  data() {
    return {

    }
  },
  
}
</script>

<style lang="less" scoped>
</style>