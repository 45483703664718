<template>
  <div class="news">
    <div class="bgimg">
      <div class="container">
        <h1>
          最新资讯
        </h1>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">平台最新通知</el-menu-item>
          <el-menu-item index="2">行业动态</el-menu-item>
          <el-menu-item index="3">政策文件 </el-menu-item>
        </el-menu>
        <ul class="list-data" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)">
          <li v-for="item of listData" :key="item.id" @click="goDetail(item)">
            <div class="title">
              <h3>{{ item.title }}</h3>
            </div>
            <div class="content">{{ delHtmlTag(item.content) }}</div>
            <div class="time">
              <span>
                {{ item.createTime|filterTimeNoHms }}
              </span>
              <span class="more-icon">
                <span class="more">查看详情</span>
                <span class="svghover"></span>
                <span class="normal"></span>
              </span>
            </div>
          </li>
        </ul>

        <div class="show-more" @click="more">
          <div>
            查看更多
          </div>
          <div class="icon"></div>
        </div>

      </div>
    </div> 
  </div>
</template>

<script>
import indexApi from "@/api/index.js";
export default {
  data() {
    return {
      loading: false,
      activeIndex: '1',
      listData: []
    }
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterTimeNoHms(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  created() {
    if (this.$route.query.type) {
      this.activeIndex = this.$route.query.type
    }
    this.renderList(this.activeIndex)
  },
  methods: {
    more() {
      this.$router.push({
        name: '最新资讯',
        query: {
          type: this.activeIndex
        }
      })
    },
    async clickNum(id) {
      await indexApi.imageClick({id})
    },
    goDetail(item) {
      this.$router.push({
        name: '最新资讯详情',
        query: {
          id: item.id
        }
      })
      this.clickNum(item.id)
    },
    delHtmlTag (val) {
      if (val) {
        return val.replace(/<[^>]+>|&[nbsp;]+/g,"")
      }
      return ''
    },
    handleSelect(key) {
      this.activeIndex = key
      this.renderList(this.activeIndex)
    },
    async renderList(type) {
      try {
        this.loading = true
        let { code, data, message } = await indexApi.findInformOrNewsList({
          page: 1,
          size: 6,
          module: type, //(1.通知公告 2.最新动态 3政策法规)
          sort: "asc",
          sortFiled: "sort",
          contentType: '1'
        });
        if (code === 200) {
          this.listData = [...data.records]
        }
      } finally {
        this.loading = false
      }
    },
  }
} 
</script>

<style lang="less" scoped>
.news {
  height: 720px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  .bgimg {
    width: 1920px;
    background-image: url(~@/assets/images/new-ui/index_news_bj@2x.png);
    height: 720px;
    background-size: 1920px;
    h1 {
      margin-top: 65px;
      color: #333;
      font-weight: bold;
      font-size: 32px;
    }
    .el-menu-demo {
      border-bottom: none;
      background: none;
      margin-top: 44px;
      .el-menu-item {
        height: 36px;
        line-height: 30px;
        padding: 0;
        font-size: 18px;
        margin-right: 56px;
        color: #999;
        border-bottom: none !important;
        transition: background-color .3s,color .3s;
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 3px;
          background: #F36E31;
          border-radius: 4px 4px 4px 4px;
          bottom: -2px;
          left: 50%;
          transform-origin: center;
          transition: width 0.2s ease,left 0.2s ease,transform 0.2s ease;
        }
        &:hover::after {
          width: 39px;
          left: 50%;
          transform: translateX(-50%);
        }
        &:hover {
          color: #F36E31;
          background: none;
          border-bottom: none !important;
          font-weight: bold;
        }
      }
      .is-active {
        color: #333;
        border-bottom: none;
        position: relative;
        color: #F36E31;
        font-weight: bold;
        background: none;
        &::after {
          content: '';
          position: absolute;
          width: 39px;
          height: 3px;
          background: #F36E31;
          border-radius: 4px 4px 4px 4px;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &::before {
        // content: normal;
      }
      &::after {
        // content: normal;
      }
    }
    .list-data {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;
      &::after {
        content: "";
        height: 0;
        width: 374px;
      }
      li {
        width: 374px;
        height: 138px;
        margin-bottom: 40px;
        cursor: pointer;
        &:hover {
          .title {
            margin-bottom: 6px;
          }
          .title::after {
            content: '';
            position: absolute;
            width: 374px;
            height: 1px;
            background-color: #F36E31;
            bottom: -1px;
            left: 0;
          }
          .more-icon {
            margin-right: 0px !important;
          }
          .more {
            opacity: 1 !important;
            margin-left: 20px;
          }
          .normal {
            width: 0px !important;
          }
          .svghover {
            opacity: 1 !important;
          }
        }
        .title {
          position: relative;
          padding-bottom: 12px;
          margin-bottom: 12px;
          border-bottom: 1px solid #DEDEDE;
          transition: all 0.4s ease;
          &::after {
            content: '';
            position: absolute;
            width: 0px;
            height: 1px;
            background-color: #F36E31;
            bottom: -1px;
            left: 0;
            transition: width 0.4s ease-in-out;
          }
          h3 {
            color: #333;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .content {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: 14px;
          color: #999;
          line-height: 24px;
          min-height: 48px;
          margin-bottom: 16px;
        }
        .time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999;
          font-size: 14px;
          position: relative;
          .more-icon {
            margin-right: 10px;
            position: relative;
            transition: all 0.3s;
          }
          .more {
            color: #F36E31;
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 14px;
            margin-right: 4px;
          }
          .svghover {
            opacity: 0;
            width: 24px;
            height: 24px;
            transition: all 0.4s;
            display: inline-block;
            background-image: url(~@/assets/images/new-ui/ic_xq_hover.svg);
          }
          .normal {
            transition: all 0.4s;
            position: absolute;
            right: 0;
            top: 0;
            width: 24px;
            height: 24px;
            background-image: url(~@/assets/images/new-ui/ic_xq_normal.svg);
            background-position: right;
          }
        }
      }
    }
    .show-more {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: #999;
      align-items: center;
      transition: color 0.2s;
      cursor: pointer;
      margin-top: 10px;
      div {
        line-height: 24px;
      }
      &:hover {
        color: #F36E31;
        .icon {
          background-image: url(~@/assets/images/new-ui/ic_more_hover.svg);
        }
      }
      .icon {
        background-image: url(~@/assets/images/new-ui/ic_more.svg);
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
        display: block;
        
      }
    }
  }
}
</style>