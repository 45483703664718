<template>
  <div class="show-data container" id="showData">
    <div>
      <span class="number">
        {{ showIndex1 }}
        <span class="unit">次</span>
      </span>
      <div>访问次数</div>
    </div>
    <div>
      <span class="number">
        {{ showIndex2 }}
        <span class="unit">人</span>
      </span>
      <div>合作用户</div>
    </div>
    <div>
      <span class="number">
        {{ showIndex3 }}
        <span class="unit">元</span>
      </span>
      <div>项目招标金额</div>
    </div>
  </div>
</template>

<script>
import gsap from '@/utils/gsap.min.js'
import indexApi from "@/api/index.js";
export default {
  data() {
    return {
      index1: 0,
      index2: 0,
      index3: 0,
      newIndex1: 0,
      newIndex2: 0,
      newIndex3: 0,
      lastScrollTop: 0 // 用于记录上一次滚动位置
    }
  },
  async created() {
    let {data} = await indexApi.getStatistics()
    this.index1 = data.visitCount
    this.index2 = data.userNum
    this.index3 = data.totalAmount
  },
  computed: {
    showIndex1: function() {
      if (this.newIndex1.toFixed(0).length > 3) {
        return this.newIndex1.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return this.newIndex1.toFixed(0);
    },
    showIndex2: function() {
      if (this.newIndex2.toFixed(0).length > 3) {
        return this.newIndex2.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return this.newIndex2.toFixed(0);
    },
    showIndex3: function() {
      if (this.newIndex3.toFixed(0).length > 3) {
        return this.newIndex3.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return this.newIndex3.toFixed(0);
    },
  },
  watch: {
    index1: function(newValue) {
      gsap.to(this.$data, { duration: 1, newIndex1: newValue });
    },
    index2: function(newValue) {
      gsap.to(this.$data, { duration: 1, newIndex2: newValue });
    },
    index3: function(newValue) {
      gsap.to(this.$data, { duration: 1, newIndex3: newValue });
    },
  }
}
</script>

<style lang="less" scoped>
  .show-data {
    height: 120px;
    background: linear-gradient(180deg, #F4F4F4 0%, #FFFFFF 100%);
    box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
    border-radius: 12px 12px 12px 12px;
    border: 2px solid #FFFFFF;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: -16px;
      >div {
        color: #666;
        font-size: 14px;
      }
      &:nth-child(2) {
        height: 60px;
        border-left: 1px solid #DEDEDE;
        border-right: 1px solid #DEDEDE;
      }
    }
    .number {
      color: #333;
      font-weight: bold;
      font-size: 32px;
      position: relative;
      margin-bottom: 10px;
      .unit {
        position: absolute;
        top: -3px;
        right: -16px;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
</style>