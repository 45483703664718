<template>
  <div class="help">
    <div class="container">
      <h1>帮助中心</h1>
      <div class="shadow-box">
        <div class="nav">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1">操作手册</el-menu-item>
            <el-menu-item index="2">常见问题</el-menu-item>
          </el-menu>
          <div class="right" @click="more">
            查看更多
            <div class="icon"></div>
          </div>
        </div>
        <div class="content">
          <div class="left">
            <img v-show="activeIndex == '1'" src="@/assets/images/new-ui/index_help_czsc@2x.png" alt="">
            <img v-show="activeIndex == '2'" src="@/assets/images/new-ui/index_help_cjwt@2x.png" alt="">
          </div>
          <div class="right">
            <ul class="right-list" v-loading="loading" element-loading-background="rgba(250, 251, 252, 0.8)">
              <li v-for="item of listData" :key="item.id" @click="goDetail(item)">
                <div class="title">
                  <h3>
                    {{ item.title }}
                  </h3>
                  <div>
                    <span class="more">查看详情</span>
                    <img class="svghover" src="@/assets/images/new-ui/ic_xq_hover.svg" alt="">
                  </div>
                </div>
                <div class="time"> {{ item.createTime|filterTimeNoHms }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indexApi from "@/api/index.js";
export default {
  data() {
    return {
      loading: false,
      activeIndex: '1',
      listData: []
    }
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterTimeNoHms(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  created() {
    this.renderList()
  },
  methods: {
    more() {
      this.$router.push({
        name: '帮助中心',
        query: {
          type: this.activeIndex
        }
      })
    },
    async clickNum(id) {
      await indexApi.imageClick({id})
    },
    goDetail(item) {
      this.$router.push({
        name: '帮助中心文章详情',
        query: {
          id: item.id,
        }
      })
      this.clickNum(item.id)
    },
    async renderList() {
      try {
        this.loading = true
        let { code, data, message } = await indexApi.findInformOrNewsList({
          page: 1,
          size: 5,
          module: '6', //(1.通知公告 2.最新动态 3政策法规 6 帮助中心)
          serviceType: this.activeIndex,
          sort: "asc",
          sortFiled: "sort",
          contentType: '1'
        });
        if (code === 200) {
          this.listData = [...data.records]
        }
      } finally {
        this.loading = false
      }
    },
    handleSelect(key) {
      this.activeIndex = key
      this.renderList()
    }
  }
}
</script>

<style lang="less" scoped>
 .help {
    margin: 42px 0;
    h1 {
      color: #333;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    .shadow-box {
      height: 482px;
      background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
      box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
      border-radius: 12px 12px 12px 12px;
      border: 2px solid #FFFFFF;
      padding: 28px;
    }
    .el-menu-demo {
      border-bottom: none;
      background: none;
      .el-menu-item {
        height: 36px;
        line-height: 30px;
        padding: 0;
        font-size: 18px;
        margin-right: 56px;
        color: #999;
        border-bottom: none !important;
        transition: background-color .3s,color .3s;
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 3px;
          background: #F36E31;
          border-radius: 4px 4px 4px 4px;
          bottom: -2px;
          left: 50%;
          transform-origin: center;
          transition: width 0.2s ease,left 0.2s ease,transform 0.2s ease;
        }
        &:hover::after {
          width: 39px;
          left: 50%;
          transform: translateX(-50%);
        }
        &:hover {
          color: #F36E31;
          background: none;
          border-bottom: none !important;
          font-weight: bold;
        }
      }
      .is-active {
        color: #333;
        border-bottom: none;
        position: relative;
        color: #F36E31;
        font-weight: bold;
        background: none;
        &::after {
          content: '';
          position: absolute;
          width: 39px;
          height: 3px;
          background: #F36E31;
          border-radius: 4px 4px 4px 4px;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &::before {
        // content: normal;
      }
      &::after {
        // content: normal;
      }
    }
    .nav {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      .right {
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: #999;
        align-items: center;
        transition: color 0.2s;
        cursor: pointer;
        div {
          line-height: 24px;
        }
        &:hover {
          color: #F36E31;
          .icon {
            background-image: url(~@/assets/images/new-ui/ic_more_hover.svg);
          }
        }
        .icon {
          background-image: url(~@/assets/images/new-ui/ic_more.svg);
          width: 24px;
          height: 24px;
          background-size: 24px 24px;
          display: block;
          
        }
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      .left {
        margin-right: 20px;
        img {
          height: 357px;
        }
      }
      .right {
        flex: 1;
        .right-list {
          height: 100%;
          width: 100%;
          padding-top: 4px;
          display: flex;
          flex-direction: column;
          >li {
            position: relative;
            padding-left: 15px;
            color: #333;
            font-size: 16px;
            margin-bottom: 20px;
            cursor: pointer;
            display: flex;
            flex-direction: column;

            
            .title {
              display: flex;
              justify-content: space-between;
              align-items: center;

            }
            .time {
              color: #999;
              font-size: 14px;
              margin-top: 8px;
            }
            &:hover {
              .svghover {
                margin-right: 0px;
              }
              .more {
                opacity: 1;
              }
            }
            .more {
              color: #F36E31;
              opacity: 0;
              transition: opacity 0.3s;
              font-size: 14px;
              margin-right: 4px;
            }
            .svghover {
              transition: all 0.3s;
              margin-right: 10px;
            }
            h3 {
              width: 520px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &:hover::after {
              border: 2px solid #F36E31;
            }
            &::after {
              content: '';
              position: absolute;
              width: 6px;
              height: 6px;
              border: 2px solid #999999;
              border-radius: 6px;
              top: 29%;
              left: 0;
              transform: translateY(-50%);
              transition: border-color .3s;
            }
          }
        }
      }
    }
 }
</style>