<template>
  <div class="info-search container">
    <h1>招采信息检索</h1>
    <div class="search">
      <SearchInput placeholder="请输入项目关键词进行搜索" @serch="serch"></SearchInput>
    </div>
    <div class="carousel">
      <div>
        <img src="@/assets/images/new-ui/ic_hot.svg" alt="">
        <el-carousel height="24px" direction="vertical" indicator-position="none" arrow="never">
          <el-carousel-item >
            <h3 @click="jump(hot)" class="medium"> 
              <span>{{hot.noticeTitle}}</span>
              <span>{{ hot.releaseTime|filterStartOf }}</span></h3>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="tabs">
      <div class="tab-title">
        <div class="left">
          <div :class="active === 1 ? 'active' : ''" @click="changeActive(1)">
            <img v-show="active === 1" src="@/assets/images/new-ui/ic_gcjs.svg" alt="">
            <div v-show="active !== 1" class="img"></div>
            工程建设
          </div>
          <div :class="active === 2 ? 'active' : ''" @click="changeActive(2)">
            <img v-show="active === 2" src="@/assets/images/new-ui/ic_cg_hover.svg" alt="">
            <div v-show="active !== 2" class="img"></div>
            采购
          </div>
          <div :class="active === 3 ? 'active' : ''" @click="changeActive(3)">
            <img v-show="active === 3" src="@/assets/images/new-ui/ic_kszc_hover.svg" alt="">
            <div v-show="active !== 3" class="img"></div>
            快速招采
          </div>
        </div>
        <div class="right" @click="more">
          <div>
            查看更多
          </div>
          <div class="icon"></div>
        </div>
      </div>
      <div class="content">
        <el-menu v-if="active !== 3" :default-active="activeNav" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item :index="item.moduleName" v-for="(item) of navList" :key="item.id">
            <el-badge :value="item.num" :max="999" class="item">
              {{ item.moduleName }}
            </el-badge>
          </el-menu-item>
        </el-menu>
        <el-menu v-else :default-active="activeFastNav" class="el-menu-demo" mode="horizontal" @select="handleSelectFast">
          <el-menu-item index="全部">
            <el-badge :value="fastList['全部']" :max="999" class="item">
              全部
            </el-badge>
          </el-menu-item>
          <el-menu-item index="招标公告">
            <el-badge :value="fastList['招标公告']" :max="999" class="item">
              招标公告
            </el-badge>
          </el-menu-item>
          <el-menu-item index="结果公示">
            <el-badge :value="fastList['结果公示']" :max="999" class="item">
              结果公示
            </el-badge>
          </el-menu-item>
        </el-menu>
        <InfoList v-if="active !== 3" v-loading="loading" ref="infoList" :propList="listData"></InfoList>
        <fastList v-else v-loading="loading" :propList="fastlistData" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from './SearchInput.vue'
import indexApi from "@/api/index";
import InfoList from "@/components/TradeInfo/components/InfoList.vue"
import fastList from "@/components/TradeInfo/components/fastList.vue"

export default {
  components: {
    SearchInput, InfoList, fastList
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterTimeNoHms(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    filterStartOf(val) {
      return moment(val).startOf('minute').fromNow();
    }
  },
  data() {
    return {
      loading: false,
      active: 1,
      engNavList: [],
      govNavList: [],
      navList: [],
      listData: [],
      fastlistData: [],
      tranNavigation: [],
      activeNav: '',
      activeFastNav: '',
      hot: {},
      fastList: {}
    }
  },
  async created() {
    // 获取导航菜单
    let res = await indexApi.findTradeNav()
    this.engNavList = res.data[0].children;
    this.govNavList = res.data[1].children;
    this.tranNavigation = [...res.data]
    this.navList = res.data[0].children;
    this.activeNav = this.navList[0].moduleName
    this.renderList();
    this.getHot()
  },
  methods: {
    jump(item) {
      this.$refs.infoList.jumpDetail({...item, type: 'notice'})
    },
    more() {
      this.$router.push({
        name: '招采信息',
        query: {
          isGov: this.active,
          type: this.activeNav,
        }
      })
    },
    serch(val) {
      this.$router.push({
        name: '招采信息',
        query: {
          isGov: this.active,
          type: this.activeNav,
          serch: val
        }
      })
    },
    handleSelect(key, keyPath) {
      this.activeNav = key
      this.renderList()
    },
    handleSelectFast(key) {
      this.activeFastNav = key
      this.getFastlist()
    },
    async getFastlist() {
      let apiFc = 'getAllFast'
      if (this.activeFastNav === '全部') {
        apiFc = 'getAllFast'
      } else if (this.activeFastNav === '招标公告') {
        apiFc = 'getFastNotice'
      } else if (this.activeFastNav === '结果公示') {
        apiFc = 'getFastPublicity'
      }
      try {
        this.loading = true
        let {data} = await indexApi[apiFc]({
          page: 1,
          size: 10,
          // sort: "desc",
          // sortFiled: "release_time"
        })
        this.fastlistData = data.records;
      } finally {
        this.loading = false
      }
    },
    async getSubscript() {
      let {data} = await indexApi.getSubscript()
      this.fastList = {...data}
    },
    async renderList() {
      try {
        this.loading = true
        let { code, data, message } = await indexApi.findTradeList({
          isGov: this.active,
          type: this.activeNav,
          page: 1,
          size: 10,
          sort: "desc",
          sortFiled: "release_time"
        });
        this.listData = data.records;
      } finally {
        this.loading = false
      }
    },
    async getHot() {
      let {data} = await indexApi.getHot({
        isGov: this.active
      })
      this.hot = data
    },
    changeActive(val) {
      if (val !== this.active) {
        this.active = val
        if (val === 3) {
          this.getSubscript()
          this.activeFastNav = '全部'
          this.getFastlist()
        } else {
          this.navList = this.tranNavigation[this.active - 1].children;
          this.activeNav = this.navList[0].moduleName
          this.renderList();
          this.getHot()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-menu-item:not(.is-disabled):hover {
  background: transparent;
}
/deep/ .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
  background: transparent;

}
  .info-search {
    >h1 {
      margin-top: 32px;
      margin-bottom: 40px;
      color: #333;
      font-weight: bold;
      font-size: 32px;
      text-align: center;
    }
    .search {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      >div {
        width: 982px;
        height: 64px;
      }
    }
    .carousel {
      display: flex;
      justify-content: center;
      >div {
        width: 982px;
        height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-carousel {
          width: 100%;
          margin-left: 6px;
        }
      }
      .medium {
        color: #333;
        line-height: 27px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        transition: color 0.2s;
        span {
          &:nth-child(1) {
            width: 90%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:hover {
              color: #F36E31;
            }
          }
        }
        >span {
          color: #999;

        }
      }
    }
    .tabs {
      margin-top: 40px;
      .tab-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          .active {
            background: linear-gradient(90deg, #FF712B 0%, #FFA34A 100%);
            box-shadow: 0px 8px 16px 1px rgba(243,110,49,0.3);
            border: 1px solid #F36E31;
            color: #fff;
          }
          >div {
            width: 163px;
            height: 64px;
            background: linear-gradient(180deg, #F3F5F7 0%, #FFFFFF 100%);
            box-shadow: 0px 8px 16px 1px rgba(0,0,0,0.05);
            border-radius: 12px 12px 12px 12px;
            border: 2px solid #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #999;
            margin-right: 16px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
              background: linear-gradient(90deg, #FF712B 0%, #FFA34A 100%);
              box-shadow: 0px 8px 16px 1px rgba(243,110,49,0.3);
              border: 1px solid #F36E31;
              color: #fff;
            }
            &:nth-child(1) {
              &:hover .img {
                background-image: url(~@/assets/images/new-ui/ic_gcjs.svg);
              }
              .img {
                width: 32px;
                height: 32px;
                background-image: url(~@/assets/images/new-ui/ic_gcjs_normal.svg);
                margin-right: 8px;
              }
            }
            &:nth-child(2) {
              &:hover .img {
                background-image: url(~@/assets/images/new-ui/ic_cg_hover.svg);
              }
              .img {
                width: 32px;
                height: 32px;
                background-image: url(~@/assets/images/new-ui/ic_cg.svg);
                margin-right: 8px;
              }
            }
            &:nth-child(3) {
              &:hover .img {
                background-image: url(~@/assets/images/new-ui/ic_kszc_hover.svg);
              }
              .img {
                width: 32px;
                height: 32px;
                background-image: url(~@/assets/images/new-ui/ic_kszc.svg);
                margin-right: 8px;
              }
            }
            
            img {
              margin-right: 8px;
            }
          }
        }
        .right {
            display: flex;
            justify-content: center;
            font-size: 14px;
            color: #999;
            align-items: center;
            transition: color 0.2s;
            cursor: pointer;
            div {
              line-height: 24px;
            }
            &:hover {
              color: #F36E31;
              .icon {
                background-image: url(~@/assets/images/new-ui/ic_more_hover.svg);
              }
            }
            .icon {
              background-image: url(~@/assets/images/new-ui/ic_more.svg);
              width: 24px;
              height: 24px;
              background-size: 24px 24px;
              display: block;
              
            }
          }
      }
      .content {
        margin-top: 12px;
        .el-menu-demo {
          // border-bottom: none;
          padding-left: 34px;
          background: transparent;
          border-bottom: solid 1px #F4F4F4;
          .el-menu-item {
            height: 40px;
            line-height: 40px;
            padding: 0;
            margin-right: 40px;
            font-size: 16px;
            color: #999;
            border-bottom: none !important;
            transition: background-color .3s,color .3s;
            /deep/ .el-badge__content {
              background-color: #FA5151;
              top: 16px;
              font-weight: normal;
              right: 2px;   
              display: flex;
              align-items: center;
            }
            &::after {
              content: '';
              position: absolute;
              width: 0;
              height: 3px;
              background: #F36E31;
              border-radius: 4px 4px 4px 4px;
              bottom: -2px;
              left: 50%;
              transform-origin: center;
              transition: width 0.2s ease,left 0.2s ease,transform 0.2s ease;
            }
            &:hover::after {
              width: 39px;
              left: 50%;
              transform: translateX(-50%);
            }
            &:hover {
              color: #F36E31;
              font-weight: bold;
              border-bottom: none !important;
            }
          }
          .is-active {
            color: #F36E31;
            border-bottom: none;
            position: relative;
            font-weight: bold;
            font-size: 16px;
            &::after {
              content: '';
              position: absolute;
              width: 39px;
              height: 3px;
              background: #F36E31;
              border-radius: 4px 4px 4px 4px;
              bottom: -2px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
</style>