<template>
  <div class="platform-total">
    <div class="container">
      <!-- left -->
      <!-- <div class="left">平台统计</div> -->

      <!-- right -->
      <ul class="right">
        <li class="msg-num">
          <p class="num">{{ total.releaseCount }}</p>
          <p class="title">发布信息</p>
        </li>
        <li class="visit-num">
          <p class="num">{{ total.visitCount }}</p>
          <p class="title">访问次数</p>
        </li>
        <li class="trade-num">
          <p class="num">{{ total.dealCount }}</p>
          <p class="title">成交数</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import indexApi from "@/api/index";

export default {
  created() {
    // 获取访问次数（平台统计）
    indexApi.getVisitNum().then(res => {
      this.total.releaseCount = res.data.releaseCount; //发布信息
      this.total.visitCount = res.data.visitCount; //访问次数
      this.total.dealCount = res.data.dealCount; //成交数
    });
  },
  data() {
    return {
      total: { releaseCount: 0, visitCount: 0, dealCount: 0 }
    };
  }
};
</script>

<style lang="less" scoped>
.platform-total {
  .container {
    display: flex;
    padding: 10px 0;
    background: #ECECF1;
    // left
    .left {
      flex: 0 0 250px;
      font-size: 24px;
      text-align: center;
      line-height: 80px;
      border-right: 1px solid #fff;
    }

    // right
    .right {
      flex: 1;
      display: flex;
      padding: 0 40px;
      justify-content: space-between;
      li {
        flex: 1;
        text-align: center;
        margin: 20px;
        min-width: flex;
        // &.msg-num {
        //   background: url("../../assets/images/total发布信息bg.png") no-repeat
        //     center;
        //   background-size: auto 74px;
        // }
        // &.visit-num {
        //   background: url("../../assets/images/total访问次数bg.png") no-repeat
        //     center;
        //   background-size: contain;
        // }
        // &.trade-num {
        //   background: url("../../assets/images/total成交数bg.png") no-repeat
        //     center;
        //   background-size: auto 86px;
        // }
        .num {
          font-size: 36px;
          margin: 8px 0;
          border-radius: 8px;
          background: #FF5C00;
          padding: 30px 40px;
          color: #fff;
          font-weight: 600;
          letter-spacing: 1px;
        }
        .title {
          font-size: 14px;
          color: #20201E;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>